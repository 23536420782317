import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline'];
const SIZES = ['btn--medium', 'btn--large', 'btn--xlarge'];

export const Button = ({
    children, 
    type, 
    onClick, 
    buttonStyle,
    buttonSize,
    externalUrl
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

    const extUrl = externalUrl;

    if(extUrl){
        return (
        
            // <Link className='btn-mobile'>
                <button className={`btn ${checkButtonStyle} ${checkButtonSize} btn-mobile`}
                    onClick={onClick}
                    type={type}
                >
                    <a href={extUrl} className="aClass" target="_blank" rel="noreferrer">{children}</a>


                    {/* {children} */}
                </button>
            // </Link>
        )
    }
    else{
        return (
        
            <Link to='/mint' className='btn-mobile'>
                <button className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                    onClick={onClick}
                    type={type}
                >
                    {children}
                </button>
            </Link>
        )
    }
    
    
}