import WindowSize from '../../helpers/WindowSize';
import './Roadmap.css'
import Fade from 'react-reveal/Fade';
import Snowfall from 'react-snowfall';

function Roadmap() {
    const size = WindowSize();
    let isMobile = (size.width <= 960);

    if(isMobile){
        return (
            <div className='containerRoadmapMobile'>
                <img src={'/images/RoadmapUpdated.png'} className="backgroundRoadmapMobile" alt=''/>
                <Snowfall
                    snowflakeCount={1000}
                    style={{ height: '180vh' }}
                />                       
                <div className="containerHeaderMobile">
                    <Fade top>
                        <img src={'/images/RoadmapText.png'} className="headerMobile" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresentMobile" style={{
                    transform: 'translate(10px, 70px)',
                    height:'22vh',
                    width: '70%'
                }}>
                    <Fade left>
                        <img src={'/images/20Text.png'} className="present10Mobile" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresentMobile"  style={{
                    transform: 'translate(50px, 50px)',
                    width: '90%'
                }}>
                    <Fade left>
                        <img src={'/images/40Text.png'} className="present10Mobile" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresentMobile" style={{
                    transform: 'translate(-50px, -10px)',
                    width: '100%'
                }}>
                    <Fade left>
                        <img src={'/images/60Text.png'} className="present10Mobile" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresentMobile" style={{
                    transform: 'translate(10px, 0px)',
                    width: '100%'
                }}>
                    <Fade left>
                        <img src={'/images/80Text.png'} className="present10Mobile" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresentMobile"  style={{
                    transform: 'translate(-10px, -10px)',
                    height:'32vh',
                    width: '100%'
                }}>
                    <Fade left>
                        <img src={'/images/100Text.png'} className="present10Mobile" alt=''/>
                    </Fade>
                </div>
            </div>
        );
    }
    else{
        return (
            <div className='containerRoadmap'>
                <img src={'/images/RoadmapUpdated.png'} className="backgroundRoadmap" alt=''/>
                <Snowfall
                    snowflakeCount={2000}
                    style={{ height: '400vh' }}
                />
                <div className="containerHeader">
                    <Fade top>
                        <img src={'/images/RoadmapText.png'} className="present10" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresent" style={{
                    transform: 'translate(-200px, 370px)' 
                }}>
                    <Fade left>
                        <img src={'/images/20Text.png'} className="present10" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresent" style={{
                    transform: 'translate(50px, 300px)' 
                }}>
                    <Fade right>
                        <img src={'/images/40Text.png'} className="present10" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresent" style={{
                    transform: 'translate(-50px, 200px)' 
                }}>
                    <Fade left>
                        <img src={'/images/60Text.png'} className="present10" alt=''/>
                    </Fade>
                </div>
                <div className="containerPresent" style={{
                    transform: 'translate(-100px, 0)' 
                }}>
                    <Fade right>
                        <img src={'/images/80Text.png'} className="present10" alt='' style={{
                            height: '50vh',
                            width: '50%' 
                        }}/>
                    </Fade>
                </div>
                <div className="containerPresent" style={{
                    transform: 'translate(-100px, 0)' 
                }}>
                    <Fade left>
                        <img src={'/images/100Text.png'} className="present10" alt=''  style={{
                            height: '65vh',
                            width: '55%' 
                        }}/>
                    </Fade>
                </div>
                
            </div>
        );
    }
    
}

export default Roadmap