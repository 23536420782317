import React from 'react';
import './App.css';
import Navbar  from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mint from './components/pages/Mint'
import OurMission from './components/pages/OurMission';
import Roadmap from './components/pages/Roadmap';
import Team from './components/pages/Team';
import './fonts/Funkytown.ttf';
import './fonts/FunkyVibes.ttf';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path ='/mint' element={<Mint />} />
          <Route path ='/' element={<OurMission />} />
          <Route path ='/roadmap' element={<Roadmap />} />
          <Route path ='/team' element={<Team />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;