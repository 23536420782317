import React from 'react'
import './OurMission.css'
import WindowSize from '../../helpers/WindowSize';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Roll from 'react-reveal/Roll';
import Snowfall from 'react-snowfall'
import { Button } from '../Button';

function OurMission() {
    const size = WindowSize();
    let isMobilePortrait = (size.width <= 960);
    let isMobileLandscape = (size.height <= 600);

        if(isMobilePortrait || isMobileLandscape){
            return (
            
                <div className='container2'>
                    <img src={'/images/MissionMountains.png'} alt="Background" className="backgroundImg"/>
                    <Snowfall 
                        style={{ height: '100vh' }}
                    />
                    <div className='container3Mobile'>
                        <div className='missionHeader'>
                            <Fade top>
                                <img src={'/images/OurMission75.png'} className="ourMissionMobile" alt=''/>
                            </Fade>
                        </div>
                        <div className="mintMissionPage">
                            <Button buttonStyle='btn--outline' buttonSize='btn--large'>MINT</Button>
                        </div>
                        <img src={'/images/ElfsTests/ElfBoyChristmas.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlChristmasLizard.png'} className="elfRight" alt=''/>
                        <div className='icon-scroll' />
                    </div>
                    <div className="container3Mobile">
                        <Fade left>
                            <p className="font-face-helv">A mix of passion, kindness and a desire to transform the art world while giving back to the less fortunate.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfGirlZipup.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfBoyHillbilly.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3Mobile">
                        <Fade right>
                            <p className="font-face-helv">Like Santa's Elves, we're here to help spread a little empathy around the world, by connecting technology and art, empowering a community and giving the power back to the people.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfBoyLotGuy.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlOnesie.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3Mobile">
                        <Fade left>
                            <p className="font-face-helv">Holiday Helpers will donate 25% of all sales to multiple charities throughout the United States. Each charity is carefully picked before each NFT drop, correlating to the specific holiday and theme that the drop is inspired on.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfGirlSnowboarder.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfBoyWook.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3Mobile">
                        <Fade right>
                            <p className="font-face-helv">This upcoming holiday season of 2021, we will be donating 25% of all mint sales to Toys for Tots. On top of this 25%, we will be donating 25% of all secondary sales on a monthly basis to charities that are voted on by our own community of Helpers.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfBoyCoolGuy.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlVampire.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3Mobile">
                        <Zoom>
                            <a className="font-face-helv" href="https://www.toysfortots.org/" rel="noreferrer" target="_blank">Visit Toys for Tots!</a>
                        </Zoom>
                        <img src={'/images/ElfsTests/ElfGirlSkater.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfBoyOnesie.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3Mobile">
                        <Roll bottom>
                            <h2 className="font-face-helv">'Tis the season to spread ART, share heART.</h2>
                            <br />
                            <p className="font-face-helv">Join our Discord community now to get access to presale.</p>
                            <br />
                            <Button buttonStyle='btn--outline' buttonSize='btn--large' externalUrl="https://discord.gg/hngfajavcj">Join Discord</Button>
                        </Roll>
                        <img src={'/images/ElfsTests/ElfBoyGlow.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlUrbanOutfittersWook.png'} className="elfRight" alt=''/>
                    </div>
                </div>
    
                
            )
        }
        else {
            return (
                <div className='container2'>
                    <img src={'/images/MissionMountains.png'} alt="Background" className="backgroundImg"/>
                    <Snowfall 
                        style={{ height: '100vh' }}
                    />
                    <div className='container3'>
                        <div className='missionHeader'>
                            <Fade top>
                                <img src={'/images/OurMission75.png'} className="ourMission" alt=''/>
                            </Fade>
                        </div>
                        <div className="mintMissionPage">
                            <Button buttonStyle='btn--outline' buttonSize='btn--xlarge'>MINT</Button>
                        </div>
                        
                        <img src={'/images/ElfsTests/ElfBoyChristmas.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlChristmasLizard.png'} className="elfRight" alt=''/>

                        <div className='icon-scroll' />
                    </div>
                    <div className="container3">
                        <Fade left>
                            <p className="font-face-helv">A mix of passion, kindness and a desire to transform the art world while giving back to the less fortunate.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfGirlZipup.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfBoyHillbilly.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3">
                        <Fade right>
                            <p className="font-face-helv">Like Santa's Elves, we're here to help spread a little empathy around the world, by connecting technology and art, empowering a community and giving the power back to the people.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfBoyLotGuy.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlOnesie.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3">
                        <Fade left>
                            <p className="font-face-helv">Holiday Helpers will donate 25% of all sales to multiple charities throughout the United States. Each charity is carefully picked before each NFT drop, correlating to the specific holiday and theme that the drop is inspired on.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfGirlSnowboarder.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfBoyWook.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3">
                        <Fade right>
                            <p className="font-face-helv">This upcoming holiday season of 2021, we will be donating 25% of all mint sales to Toys for Tots. On top of this 25%, we will be donating 25% of all secondary sales on a monthly basis to charities that are voted on by our own community of Helpers.</p>
                        </Fade>
                        <img src={'/images/ElfsTests/ElfBoyCoolGuy.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlVampire.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3">
                        <Zoom>
                            <a className="font-face-helv" href="https://www.toysfortots.org/" rel="noreferrer" target="_blank">Visit Toys for Tots!</a>
                        </Zoom>
                        <img src={'/images/ElfsTests/ElfGirlSkater.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfBoyOnesie.png'} className="elfRight" alt=''/>
                    </div>
                    <div className="container3">
                        <Roll bottom>
                            <h2 className="font-face-helv">'Tis the season to spread <b>ART</b>, share <b>heART</b>.</h2> 
                            <br />
                            <p className="font-face-helv">Join our Discord community now to get access to presale.</p>
                            <br />
                            <Button buttonStyle='btn--outline' buttonSize='btn--large' externalUrl="https://discord.gg/hngfajavcj">Join Discord</Button>
                        </Roll>
                        <img src={'/images/ElfsTests/ElfBoyGlow.png'} className="elfLeft" alt=''/>
                        <img src={'/images/ElfsTests/ElfGirlUrbanOutfittersWook.png'} className="elfRight" alt=''/>
                    </div>
                </div>
            )
        }
}

export default OurMission