import React, { useEffect, useState } from 'react'
import './Mint.css'
import '../../App.css';
import { useDispatch, useSelector } from 'react-redux'
import { connect } from '../../redux/blockchain/blockchainActions';
import { fetchData } from "../../redux/data/dataActions";
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Snowfall from 'react-snowfall';

toast.configure();

function Mint() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(NaN);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
          NAME: "",
          SYMBOL: "",
          ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        MAX_PRESALE_SUPPLY: 1,
        WEI_COST: 0,
        WEI_PRESALE_COST: 0,
        DISPLAY_COST: 0,
        DISPLAY_PRESALE_COST: 0,
        CURRENT_PRICE: 0,
        CURRENT_DISPLAY_PRICE: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
      });

    // console.table(blockchain);

    const mintNFT = () => {
        let cost = 0;
        if(data.totalSupply >= CONFIG.MAX_PRESALE_SUPPLY){
            cost = CONFIG.WEI_COST;
        }
        else{
            cost = CONFIG.WEI_PRESALE_COST;
        }
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * selectedOption.value);
        let totalGasLimit = String(gasLimit + (40000*selectedOption.value));
        // console.log("selected amount: ", selectedOption.value);
        // console.log("Cost: ", totalCostWei);
        // console.log("Gas limit: ", totalGasLimit);
        setLoading(true);
        
        blockchain.smartContract.methods
          .mintHelpers(selectedOption.value)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            maxPriorityFeePerGas: String(1500000000),
          })
          .once("error", (err) => {
            console.log(err);
            const notify = (error) => toast.error(error, { position: toast.POSITION.BOTTOM_CENTER });
            notify("Sorry, something went wrong please try again later.");
            setLoading(false);
          })
          .then((receipt) => {
            console.log(receipt);
            const notify = (success) => toast.success(success, { position: toast.POSITION.BOTTOM_CENTER });
            notify(`You have successfully purchased a ${CONFIG.NFT_NAME}!`);
            setLoading(false);
            dispatch(fetchData(blockchain.account));
          });
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
          dispatch(fetchData(blockchain.account));
        }
      };
    
    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);

        // if(data.totalSupply >= CONFIG.MAX_PRESALE_SUPPLY) {
        // }
    };
    
    useEffect(() => {
        getConfig();
    }, []);
    
    useEffect(() => {
        getData();
    }, [blockchain.account]);

    // console.log("TOTAL SUPPLY =" ,data.totalSupply);
    // console.log("CONFIG.MAX_PRESALE_SUPPLY =" ,CONFIG.MAX_PRESALE_SUPPLY);
    // console.log("CONFIG.WEI_COST=" ,CONFIG.WEI_COST);
    // console.log("CONFIG.DISPLAY_COST=" ,CONFIG.DISPLAY_COST);

    useEffect(() => {
        if(blockchain.errorMsg)
        {
            const notify = (error) => toast.error(error, { position: toast.POSITION.BOTTOM_CENTER });
            notify(blockchain.errorMsg);
        }
        
    },[blockchain.errorMsg])


    const options = [
        { value: '1', label: '1', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 1},
        { value: '2', label: '2', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 2},
        { value: '3', label: '3', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 3 },
        { value: '4', label: '4', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 4 },
        { value: '5', label: '5', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 5 },
        { value: '6', label: '6', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 6 },
        { value: '7', label: '7', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 7 },
        { value: '8', label: '8', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 8 },
        { value: '9', label: '9', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 9 },
        { value: '10', label: '10', isDisabled: (CONFIG.MAX_SUPPLY - data.totalSupply) < 10 }
    ]

        return (
            <div className="wrapperMint">
                <img src={'/images/MissionMountains.png'} alt="Background" className="backgroundImgMint"/>
                <img src={'/images/Mint.png'} className="mintLogo" alt=''/>
                <Snowfall
                        snowflakeCount={500}
                    />
                <div className="mintContainer">
                    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                        <>
                            <div className='header-mint'>
                                SOLD OUT
                            </div>
                            <div className='header-mint'>
                                Find us on <a target={"_blank"} rel="noreferrer" href={CONFIG.MARKETPLACE_LINK}>
                                {CONFIG.MARKETPLACE}
                            </a>
                            </div>

                        </>
                    ) : (
                        <>
                            {blockchain.account === "" ||
                            blockchain.smartContract === null ? (
                                <>
                                    <div className="hero-btns">
                                        <button
                                            className='btn btn--outline btn--large'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(connect());
                                                getData();
                                            } }>
                                            Connect Wallet
                                        </button>
                                    </div>
                                </>
                            ) : (
                            <>
                                <div className='header-container'>
                                    {/* <div className="header-mint font-face-helv">
                                        MINTED
                                        <div className="counter">
                                            {data.totalSupply} / {CONFIG.MAX_SUPPLY}
                                        </div>
                                    </div> */}
                                    

                                    <div className="header-mint font-face-helv">
                                        PRICE
                                        <div className='counter'>
                                            {(Number(data.totalSupply) > CONFIG.MAX_PRESALE_SUPPLY) ? CONFIG.DISPLAY_COST : CONFIG.DISPLAY_PRESALE_COST}{" "}
                                            {CONFIG.NETWORK.SYMBOL} 
                                        </div>
                                    </div>
                                    
                                </div>
                                
                                <div className="hero-btns2">
                                    <div style={{ width: '200px' }}>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            placeholder="# of Helpers to mint" />
                                    </div>
                                    <br />
                                    <br />
                                    <button
                                        className='btn btn--primary btn--large'
                                        disabled = {loading ? 1 : 0 || loading}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            mintNFT();
                                            getData();
                                        } }
                                    >
                                        {loading ? "BUSY" : "Mint"}
                                    </button>
                                </div>
                            </>
                            )}
                    </>
                    )}
                </div>
            </div>
        )
}

export default Mint
