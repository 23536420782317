import React from 'react'
import './Team.css'

function Team() {
        return (
            <div className="wrapper">
                <img src={'/images/TeamWord.png'} className="teamLogo" alt=''/>
                <div className='containerTeam'>
                    <div className="containerMember">
                        <img src={'/images/Cakes.png'} alt="Cakes" className="teamImage"/>
                        <h2 className="font-face-helv">Matt R.</h2>
                        <span className="font-face-helv">Co-Founder/ Operations</span>
                        <span className="font-face-helv">Denver, CO</span>
                    </div>
                    <div className="containerMember">
                        <img src={'/images/Sipos.png'} alt="Sipos" className="teamImage"/>
                        <h2 className="font-face-helv">David S.</h2>
                        <span className="font-face-helv">Co-Founder/ Developer</span>
                        <span className="font-face-helv">Austin, TX</span>
                    </div>
                    <div className="containerMember">
                        <img src={'/images/Bern.png'} alt="Bern" className="teamImage"/>
                        <h2 className="font-face-helv">Bernadette G.</h2>
                        <span className="font-face-helv">Artist</span>
                        <span className="font-face-helv">Allentown, PA</span>
                    </div>
                    <div className="containerMember">
                        <img src={'/images/Marina.png'} alt="Marina" className="teamImage"/>
                        <h2 className="font-face-helv">Marina P.</h2>
                        <span className="font-face-helv">Writer</span>
                        <span className="font-face-helv">São Paulo, Brazil</span>
                    </div>
                    <div className="containerMember">
                        <img src={'/images/Lauren.png'} alt="Lauren" className="teamImage"/>
                        <h2 className="font-face-helv">Lauren Y.</h2>
                        <span className="font-face-helv">Graphic Designer</span>
                        <span className="font-face-helv">Denver, CO</span>
                    </div>
                </div>
            </div>
                    
        )
}

export default Team