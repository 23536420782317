import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css';
import { Button } from './Button';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960 || window.innerHeight <= 600) {
            setButton(false);
        }
        else {
            setButton(true);
        }
    };


    useEffect(() => {
        showButton();
    },
    []);

    window.addEventListener('resize', showButton);

    let isMobilePortrait = (window.innerWidth <= 960);
    let isMobileLandscape = (window.innerHeight <= 600);
    
    if(isMobilePortrait || isMobileLandscape){
        return (
            <>
                <nav className="navbar">
                    <div className="navbar-container">
                        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                            <img src="/images/BlackBurnBlackNFT.png" alt="Helper Logo" style={{'height':'75px'}}/>
                        </Link>
                        <div className="menu-icon" onClick={handleClick}>
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                        </div>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className="nav-item">
                                <Link to='/' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Mission
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/roadmap' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Roadmap
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/team' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="/assets/BusinessModel.pdf" className="nav-links-mobile" rel="noopener noreferrer" target="_new">Plan</a>
                            </li>
                            <li className="nav-item">
                                <Link to='/mint' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    MINT
                                </Link>
                            </li>
                            <div className='nav-links-logo'>
                                <a className="nav-links" href="https://discord.gg/hngfajavcj" rel="noreferrer" target="_blank">
                                    <img src="/images/discord_icon.png" alt="Discord Logo"/>
                                </a>
                                <a className="nav-links" href="https://twitter.com/HHelpers_NFT" rel="noreferrer" target="_blank">
                                    <img src="/images/twitter-logo.png" alt="Twitter Logo"/>
                                </a>
                            </div>                       
                        </ul>
                        {button && <Button buttonStyle='btn--outline'>MINT</Button>}
                    </div>
                </nav>
            </>
        )
    }
    else {
        return (
            <>
                <nav className="navbar">
                    <div className="navbar-container">
                        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                            <img src="/images/BlackBurnBlackNFT.png" alt="Helper Logo" style={{'height':'140px'}}/>
                        </Link>
                        <div className="menu-icon" onClick={handleClick}>
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                        </div>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className="nav-item">
                                <Link to='/' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Our Mission
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/roadmap' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Roadmap
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/team' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Team
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="/assets/BusinessModel.pdf" className="nav-links-mobile">Plan</a>
                            </li>
                            <li>
                                <a className="nav-links" href="https://discord.gg/hngfajavcj" rel="noreferrer" target="_blank">
                                    <img src="/images/discord_icon.png" alt="Discord Logo"/>
                                </a>
                            </li>
                            <li> 
                                <a className="nav-links" href="https://twitter.com/HHelpers_NFT" rel="noreferrer" target="_blank">
                                    <img src="/images/twitter-logo.png" alt="Twitter Logo"/>
                                </a>
                            </li>                     
                        </ul>
                        {button && <Button buttonStyle='btn--outline'>MINT</Button>}
                    </div>
                </nav>
            </>
        )
    }
    
}

export default Navbar
